import StickyHeader from "./components/StickyHeader";

import React, { lazy, Suspense } from "react";

const ProcessCapability = lazy(() => import("./components/ProcessCapability"));

function App() {
  return (
    <div className="App font-poppins">
      <StickyHeader />
      <Suspense
        fallback={
          <div className=" mt-[130px] w-screen h-[80vh] flex justify-center items-center">
            Loading...
          </div>
        }
      >
        <div className="px-20 py-4 mt-[130px]">
          <ProcessCapability />
        </div>
      </Suspense>
    </div>
  );
}

export default App;
